<template>
<v-dialog
v-model="dialog"
 max-width="250mm"
>
<v-card>
    <v-card-title>
        Recu de paiment
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close-circle-outline</v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
<div style="background-color: white" id="printCard">
    <div id="parent" >
        <div id="header">
        <div  id="logo" > <img  class="img-responsive" width="130" height="80" src="https://i.imgur.com/TxkVGSF.png" alt="logo" > </div>
            
            <div id="title" class="mt-3">Reçu pour {{ fullName }}</div>
        </div>
        <div id="date">Date d'operation :<span> {{date}}</span></div>
        <div id="dateinsc">Date d'inscription :<span> {{inscridate}}</span></div>
        <div id="container">
        <div id="recu">Nom et Prenom:<span> {{ fullName }}</span></div>
        
        <!-- if payment is for a diplome -->
        <div v-if="type == 'diplome'" id="pour">Pour         :<span> {{ month }}</span></div>
        
        <!-- if payment is for a formation -->

        <!-- if payment is for a frais -->
         <div v-if="type == 'frais'" id="pour"> Pour         :<span> Frais d'inscription</span></div>


         <!-- if montant == 1 -->
        <div v-if="show" id="somme">La somme de :<span> {{ montant }} DH</span></div>
        <div v-if="show" id="somme">payé pour :<span> {{ user }}</span></div>
         
         <!-- if montant == 0 -->

        <div v-if="!show" id="somme">Pour l'ecole :<span> GROUPE2IS</span></div>
        <div v-if="!show" id="somme">payé pour :<span> {{ user }}</span></div>
        
        <div v-if="type != 'frais'" id="pour"> Formation        :<span> {{ classe }}</span></div>
        <div id="signature">TransactionID :<span> {{ verif }} </span></div>
    </div>
    </div>
    <br><br>
    <div id="parent">
        <div id="header">
        <div  id="logo" > <img  width="130" class="img-responsive" height="80" src="https://i.imgur.com/TxkVGSF.png" alt="logo" > </div>
            <div id="title" class="mt-3">Reçu pour GROUPE2IS</div>
        </div>
        <div id="date">Date d'operation :<span> {{date}}</span></div>
        <div id="dateinsc">Date d'inscription :<span> {{inscridate}}</span></div>
        <div id="container">
        <div id="recu">Nom et Prenom:<span> {{ fullName }}</span></div>
        
        <!-- if payment is for a diplome -->
        <div v-if="type == 'diplome'" id="pour">Pour         :<span> {{ month }}</span></div>
        
        <!-- if payment is for a formation -->

         <!-- if payment is for a frais -->
         <div v-if="type == 'frais'" id="pour"> Pour         :<span> Frais d'inscription</span></div>

         <!-- if montant == 1 -->
        <div v-if="show" id="somme">La somme de :<span> {{ montant }} DH</span></div>
        <div v-if="show" id="somme">payé pour :<span> {{ user }}</span></div>
         
         <!-- if montant == 0 -->

        <div v-if="!show" id="somme">Pour l'ecole :<span> GROUPE2IS</span></div>
        <div v-if="!show" id="somme">payé pour :<span> {{ user }}</span></div>

        <div v-if="type == 'frais'" id="pour"> Frais d'inscription pour       :<span> {{ classe }}</span></div>
        <div v-if="type != 'frais'" id="pour"> Formation       :<span> {{ classe }}</span></div>
        <div id="signature">TransactionID :<span> {{ verif }} </span></div>
    </div>
    </div>
</div>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-switch
      v-model="show"
      label="Montrer le montant"
      color="blue"
      class="mx-3"
    ></v-switch>
        <v-btn 
      class="mx-3"
        @click="print"
        color="primary"
        >Imprimer</v-btn>
    </v-card-actions>
</v-card>
<div v-show='dataimage == 3' id="CradImage">
      <img :src="image" >
</div>
</v-dialog>
</template>

<script>
export default {
    props: ['date' , 'fullName' , 'montant' , 'user' , 'classe' , 'verif' , 'month' , 'type' , 'show'],
    props: {
        dialoge: {
        type: Boolean,
        default: false
      }
      },
    data: () => ({
      dialog: false,
      date: '',
      fullName: '',
      dataimage: 0,
      output: null,
      image: null,
      montant: '',
      user: '',
      classe: '',
      verif: '',
      month: '',
      inscridate: '',
      type: '',
      show: false,

    }),
     methods: {
      openit: function(date , fullName , montant , user , classe , verif , month , type , show , inscridate) {
        this.dialog = true;
        this.date = date
        this.fullName = fullName
        this.montant = montant
        this.user = user
        this.classe = classe
        this.verif = verif
        this.month = month
        this.type = type
        this.show = show
        this.inscridate = inscridate
        if (!this.inscridate)
            this.inscridate = '---------'
        console.log(this.inscridate)
    },
    clean() {
      this.image = '';
      this.dialog = false;
    },
    print() {

    const prtHtml = document.getElementById('printCard').innerHTML;

// Get all stylesheets HTML
let stylesHtml = '';
for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
  stylesHtml += node.outerHTML;
}

// Open the print window
const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

// wait(2000);
setTimeout(WinPrint.document.close(), 2000);
setTimeout(WinPrint.focus(), 1000);
setTimeout(WinPrint.print(), 2000);

// WinPrint.focus();
// WinPrint.print();
// WinPrint.close();
    },
    
    }
}
</script>

<style scoped>
#parent{
    width: 220mm;
	height: 130mm;
	margin: auto;
	top: 30px;
    border: 6px double #666;
}

#header {
    display: inline-block;
    width: 200mm;
    margin: 2mm 2mm;
}
#logo {
    width: 70mm;
    float: left;

}

#title {
    float: center;
    font-size: 8mm;
}

#date{
    float: left;
    width: 210mm;
    margin-top: 5mm;
    margin-bottom: 4mm;
    padding-left: 115mm;
    font-size: 6mm;

}
#date span{
    padding:0 0mm 0 2mm ;
}
#dateinsc{
    float: left;
    width: 210mm;
    margin-top: 0mm;
    margin-bottom: 2mm;
    padding-left: 8mm;
    font-size: 6mm;

}
#dateinsc span{
    padding:0 0mm 0 2mm ;
}

#container {
    width: 200mm;
    margin:auto;
    font-size: 6mm;
}

#recu {
    float: left;
    width: 210mm;
    margin: 3.5mm 0;
}
#somme {
    float: left;
    width: 210mm;
    margin: 2mm 0;
}

#pour {
    float: left;
    width: 210mm;
    margin: 3.5mm 0;
}

#signature {
    float: left;
    width: 210mm;
    margin: 3.5mm -2mm;
}


#recu span{
	padding:0 5mm 0 18mm ;
	margin: auto;
}
#somme span{
	padding:0 5mm 0 5mm ;
	margin: auto;
}
#pour span{
	padding:0 5mm 0 26mm ;
	margin: auto;
}
#signature span{
	padding:0 5mm 0 14mm ;
	margin: auto;
}
</style>