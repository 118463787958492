<template>
<v-container fluid>

    <v-row>
        <v-col>
            <v-card>
    <v-toolbar
      :color="colorTab"
      dark
      flat
    >
      <v-toolbar-title>Liste Des Paiements pour --<span class='red--text font-weight-bold text-h5'> {{nameFormation}} </span>--</v-toolbar-title>

    </v-toolbar>
      <!-- Formation -->
    <v-data-table
    locale="fr"
    :search="search"
    v-if="table == 0"
    :headers="headersForm"
    :items="Formation"
    sort-by="calories"
    class="elevation-1 mt-4"
  >
    <template v-slot:top>
        
        <v-row class="mx-2">
        <v-col cols="12" lg="6" md="6" sm="12">
        <v-text-field
          v-model="search"
          outlined
          label="recherche"
        ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
        <v-select
        no-data-text="Pas de formation trouver"
          :items="FormationOptions"
          item-text="name"
          item-value="id"
          @change="getStudentsForm"
          v-model="FormationChosed"
          label="Selectionner Formation"
          outlined
        ></v-select>
        </v-col>
        </v-row>
      <v-toolbar
        flat
      >
        <v-spacer></v-spacer>
        <v-dialog :retain-focus="false"
          v-model="dialogFor"
          max-width="60%"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="colorTab"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              :disabled="!showBtn"
            >
              Ajouter paiement
              <v-icon>
                  mdi-plus
              </v-icon>
            </v-btn>
            <v-icon class="ma-4" :disabled="!showBtn" color="primary" @click="generatePDF('form')"> mdi-printer</v-icon>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text 
            v-loading="loading"
            >
              <v-container>
                <v-row align="center" align-content="center">
                  <v-col cols="12" class="pa-2">
                    <v-select
                    :color="colorTab"
                    outlined
                    :items="formStudents"
                    v-model="editedItem.id_student"
                    label="Nom du Stagiaire"
                    :error-messages="studentErrors"
                    @input="$v.editedItem.id_student.$touch()"
                    @blur="$v.editedItem.id_student.$touch()"
                    >
                    <template slot="selection" slot-scope="data">
    <!-- HTML that describe how select should render selected items -->
                    {{ data.item.firstname }}  {{ data.item.lastname }}
                        </template>
                            <template slot="item" slot-scope="data">
    <!-- HTML that describe how select should render items when the select is open -->
                                {{ data.item.firstname }}  {{ data.item.lastname }}
                                </template>
                    </v-select>
                    
                    <v-date-picker
      v-model="editedItem.month"
      landscape
      :color="colorTab"
      width="50%"
      :error-messages="monthErrors"
      @input="$v.editedItem.month.$touch()"
      @blur="$v.editedItem.month.$touch()"
      type="month"
      year-icon="mdi-calendar-blank"
      prev-icon="mdi-skip-previous"
      next-icon="mdi-skip-next"
      class="my-2"
    ></v-date-picker>
                    <v-text-field
                    :color="colorTab"
                    outlined
                      v-model="editedItem.montant"
                      :error-messages="amountErrors"
                    @input="$v.editedItem.montant.$touch()"
                    @blur="$v.editedItem.montant.$touch()"
                      label="Montant"
                      type="number"
                    ></v-text-field>
                    <v-text-field
                    :color="colorTab"
                    outlined
                      v-model="nameFormation"
                      
                      label="Filliere"
                      readonly
                    ></v-text-field>
                    <v-switch
              v-model="switchForm"
              label="Afficher le montant"
              :color="colorTab"
              value="Afficher le montant"
              hide-details
            ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeFor"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveFor"
              >
                {{ formTitle }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog :retain-focus="false" v-model="dialogDeleteFor" max-width="60%">
          <v-card>
            <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer cet paiment ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="closeDeleteFor">Non</v-btn>
              <v-btn :color="colorTab" text @click="deleteItemConfirmForm">Oui</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        v-if="item.checked == 0"
        @click="deleteItemFor(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon
      small
      @click="ViewCard(item , 0)"
      >
      mdi-eye
        </v-icon> 
    </template>
    <template v-slot:no-data>
      <v-btn
        :color="colorTab"
        disabled
        class="white--text"
      >
        Pas d'informations pour le moment
      </v-btn>
    </template>
  </v-data-table>
      </v-card>
        </v-col>
    </v-row>
    <Recu  :dialoge="recuDialog" ref="opendialog"/>
</v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Recu from '../components/Recu.vue'

const auth = {
        headers: { Authorization:'Bearer ' + localStorage.getItem('token') }
    }

  export default {

mixins: [validationMixin],
components: {
  Recu
},

    validations: {
      editedItem: {
        id_student: { required },
        montant: { required },
        month: { required },
      },
    },
    data: () => ({
      dateDialog: false,
      tableTitle: 'Formations',
      loading: false,
      dialogFor: false,
      recuDialog: false,
      switchForm: false,
      showBtn: false,
      table: 0,
      FormationsTab: [],
      classInfo: [],
      nameFormation: '',
      FormationChosed: null,
      dialogDeleteFor: false,
      colorTab: 'blue darken-4',
      formStudents: [],
      items: [
          'Formation','Diplome',
        ],
      headersForm: [
        {
          text: 'Nom',
          value: 'firstname',
        },
        { text: 'Prenom', value: 'lastname' },
        { text: 'Montant', value: 'montant' },
        { text: 'Mois', value: 'month' },
        { text: 'Verfication', value: 'verif' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      Formation: [],
      search: '',
      FormationOptions: [],
      editedIndex: -1,
      editedItem: {
        id_student: '',
        month: '',
        classe_id: null,
        montant: null,
      },
      defaultItem: {
       id_student: '',
       month: '',
        classe_id: null,
        montant: null,
      }
    }),

    mounted() {

      // Get all Formations
          this.$axios.get('formationselect'  )
          .then(({ data }) => {
            this.FormationOptions = data;
            this.FormationsTab = data;
          }).catch((e) => {
          console.log(e)
          // return e.response.data;
        });
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Ajouter' : 'Modifier'
      },
      amountErrors () {
        const errors = []
        if (!this.$v.editedItem.montant.$dirty) return errors
      
        !this.$v.editedItem.montant.required && errors.push('Le nom est requis.')
        return errors
      },

      studentErrors () {
        const errors = []
        if (!this.$v.editedItem.id_student.$dirty) return errors
      
        !this.$v.editedItem.id_student.required && errors.push('Le Stagiaire est obligatoire.')
        return errors
      },

      monthErrors () {
        const errors = []
        if (!this.$v.editedItem.month.$dirty) return errors
        !this.$v.editedItem.month.required && errors.push('Le mois de paiement est requis.')
        return errors
      },
    },

    watch: {
     
      dialogFor (val) {
        this.$v.$reset()
        this.switchForm = false
        this.editedItem.month = ''
        val || this.closeFor()
      },
      dialogDeleteFor (val) {
        val || this.closeDeleteFor()
      },

    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.Formation = []
      },

      tabChanged(data)
      {
          if (data == 0)
        {
            this.colorTab = '#096dd9'
            this.tableTitle = 'Formations'
            if (this.Formation.length > 0)
                this.showBtn = true
            else
                this.showBtn = false
        }
          this.table = data;
},

      editItemFor (item) {
        this.editedIndex = this.Formation.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogFor = true

        console.log(this.editedIndex)
      },
    ViewCard (item , show) {
        this.$refs.opendialog.openit(item.date , item.firstname+' '+item.lastname , item.montant , item.user , item.classe , item.verif , item.month , item.type , show , item.inscridate);
      },
      deleteItemFor (item) {
          console.log('here')
        this.editedIndex = this.Formation.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDeleteFor = true
      },

      deleteItemConfirmForm () {
          this.loading = true
          console.log(this.Formation[this.editedIndex].id)
        this.$axios.delete('payment/'+this.Formation[this.editedIndex].id  )
          .then(({ data }) => {
              if (data.status)
                this.success(data.message);
            else
                this.error(data.message);
          this.Formation.splice(this.editedIndex, 1)
          this.loading = false
      }).catch((e) => {
          console.log(e)
          this.loading = false
                this.error(data.message);
          // return e.response.data;
        });
        this.closeDeleteFor()
      },

      

      closeFor () {
        this.dialogFor = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDip () {
        this.dialogDip = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDeleteFor () {
        this.dialogDeleteFor = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

   

      getStudentsForm()
      {
        console.log(this.FormationChosed)
        this.$axios.get('payments/'+this.FormationChosed)
          .then(({ data }) => {
              this.formStudents = []
              if (!data.status)
                    this.error(data.message)
            if (data.students[0].firstname != 'Pas de stagiaire trouver')
                this.formStudents = data.students;
            console.log(data.students)
            this.Formation = data.paymentInfo;
            this.classInfo = data.classInfo;
            this.showBtn = true
            console.log(this.FormationChosed)
            this.nameFormation = this.FormationOptions.find(x => x.id === this.FormationChosed).name;
          }).catch((e) => {
          console.log(e)
          // return e.response.data;
        });

      },
      

      clear(){

      },

      

      success(message) {
        this.$notify({
          title: 'Success',
          message: message,
          type: 'success',
        });
      },
      error(message) {
        this.$notify({
          title: 'Error',
          message: message,
          type: 'error'
        });
      },

       saveFor () {
        this.editedItem.class_id = this.FormationChosed
        this.editedItem.student_id = this.editedItem.id_student.id

        console.log(this.editedItem)
        this.$v.$touch()
        if (!this.$v.$error)
        {
         this.loading = true
        this.$axios.post('payment', this.editedItem)
          .then(({ data }) => {
            this.dialogFor = false
            this.loading = false;
            if (data.status)
            {
              this.Formation.push(data.paymentInfo[0])
              this.success(data.message)
            }
            else
                this.error(data.message)
              this.editedItem = this.defaultItem
              }).catch(({ error }) =>{
                  this.loading = false;
                console.log(error)
              this.editedItem = this.defaultItem
                this.error('Error Survenu');
              })
        this.closeFor()
        }
      },
      save (date) {
        this.$refs.menu.save(date)
      },

      generatePDF(type) {


      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter"
      });

        if (type == 'form'){
      const columns = [
        { title: "Nom", dataKey: "firstname" },
        { title: "Prenom", dataKey: "lastname" },
        { title: "Montant", dataKey: "montant" },
        { title: "Mois", dataKey: "month" },
        { title: "Verification Code", dataKey: "verif" },
      ];

       const headers = [
        { title: "Nom", dataKey: "name" },
        { title: "Niveau", dataKey: "niv" },
        { title: "Les jours", dataKey: "days" },
        { title: "Formateur", dataKey: "profName" },
        { title: "Debut", dataKey: "start_hour" },
        { title: "Finir", dataKey: "end_hour" },
      ];
      // text is placed using x, y coordinates
    //   doc.setFontSize(16).text(headers, 0.5, 1.0);
      // create a line under heading 
      doc.setLineWidth(0.01).line(0.5, 1.1, 8.0, 1.1);
      
      console.log(this.classInfo)
      // Using autoTable plugin
      doc.autoTable({
        columns:headers,
        body: this.classInfo,
        startY: 1.5,
        margin: { left: 0.5, top: 1.25 }
      });

      doc.autoTable({
        columns,
        columnStyles: {
            0: {cellWidth: 1 , fontSize: 9},
        },
        body: this.Formation,
        margin: { left: 0.5, top: 1.25 }
      });
        }
    window.open(doc.output("bloburl"));
    },
    },
  }
</script>

<style scoped>



.notif {
  background-color: rgb(34, 155, 34);
}
</style>