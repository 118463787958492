<template>
<v-container fluid>

    <v-row>
        <v-col>
  <v-data-table
    :headers="headers"
    :items="formateurs"
    sort-by="calories"
    v-loading="loading"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Les Formateurs</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
        v-loading="loading"
          v-model="dialog"
          max-width="700px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Ajouter Formateur

              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }} Filliere</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="10"
                    md="6"
                  >
                    <v-text-field
                    required
                    outlined
                    v-model="editedItem.name"
                    :error-messages="nameErrors"
                    @input="$v.editedItem.name.$touch()"
                    @blur="$v.editedItem.name.$touch()"
                    label="Nom du formateur"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="10"
                    md="6"
                  >
                    <v-text-field
                    required
                    outlined
                    type="number"
                    v-model="editedItem.phone"
                    label="Numero de telephone"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="10"
                    md="6"
                  >
                    <v-text-field
                    required
                    outlined
                    v-model="editedItem.email"
                    label="Email"
                    ></v-text-field>
                  </v-col>

                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                {{ formTitle }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-loading="loading" v-model="dialogDelete" max-width="600px">
          <v-card class="text-center">
            <v-card-title class="text-h5">Voulez-vous vraiment supprimer ce formateur ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Non</v-btn>
              <v-btn color="red darken-1" text @click="deleteItemConfirm">Oui</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.niv_form="{ item }">
      <v-chip
        :color="getColor(item.niv_form)"
        dark
      >
        {{ item.niv_form }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
      >
        Rafraîchir
      </v-btn>
    </template>
  </v-data-table>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'


export default ({

  mixins: [validationMixin],

    validations: {
      editedItem: {
        name: { required },
      },
    },
    data: () => ({

        dialog: false,
        loading: false,
        niveau: ['Technicien' , 'Technicien Specialise'],
      dialogDelete: false,
      headers: [
        {
          text: 'Nom du formatteur',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      formateurs: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        phone: '',
        email: '',
      },
      defaultItem: {
        name: '',
        phone: '',
        email: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Ajouter' : 'Modifier'
      },
       nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
      
        !this.$v.editedItem.name.required && errors.push('Le nom est requis.')
        return errors
      },
    },
    mounted() {
        // Formateurs GET
        this.loading = true
        this.$axios.get('formateurs')
        .then(({ data }) =>{
            this.formateurs = data
            this.loading = false
        }).catch((e) => {
            this.loading = false
            console.log(e)
        })
    },
    watch: {
      dialog (val) {
        this.$v.$reset()
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    methods: {
      editItem (item) {
        this.editedIndex = this.formateurs.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.formateurs.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
          this.loading = true
          const ind = this.editedIndex
          this.$axios.delete('formateur/'+this.formateurs[this.editedIndex].id ).
            then(({ data }) => {
                console.log(data)
                this.formateurs.splice(ind, 1)
                this.loading = false
                this.success(data.message)
            }).catch((e) => {
                this.error(e.message)
            })
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

        success(message) {
        this.$notify({
          title: 'Success',
          message: message,
          type: 'success',
        });
      },

      error(message) {
        this.$notify({
          title: 'Error',
          message: message,
          type: 'error'
        });
      },

      save () {
        this.$v.$touch()

        if (!this.$v.$error) {
          this.loading = true
              if (this.editedIndex > -1) {
                  console.log(this.editedItem)
                  this.$axios.put('formateur/'+this.formateurs[this.editedIndex].id,
                  this.editedItem).
                  then(({data})=> {
                        
                        Object.assign(this.formateurs[this.editedIndex], data.formateur)
                        this.loading = false
                        console.log(data.message)
                        this.success(data.message)
                        this.close()
                  }).catch((e) => {
                      
                    this.loading = false
                    this.error(e.message)
                    console.log('error: ', e)
                  })
              }
              else {
                  console.log(this.editedItem)
          this.$axios.post('formateur' , this.editedItem)
          .then(({ data }) =>{
                this.formateurs.push(data.formateur)
          this.loading = false
          this.success(data.message)
              this.close()
          }).catch((e) => {
          this.loading = false
          this.success(e.message)
              console.log('error: ', e)
          })
        }
        }
      },

      getColor (niv_form) {
        if (niv_form == "Technicien") return 'teal lighten-1'
        else if (niv_form == "Technicien specialise") return 'brown darken-2'
      },
    },
})
</script>
