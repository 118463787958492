<template>
<v-container fluid>
  <v-row>
    <v-col>
      <v-card>
    <v-toolbar
      color="cyan"
      dark
      flat
    >
      <v-toolbar-title>Liste Des Stagiaires</v-toolbar-title>
      </v-toolbar>
  <v-data-table
    v-loading="loading"
    :headers="headers"
    :items="students"
    sort-by="calories"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:top>
      <v-row class="mx-2 my-2">
        <v-col cols="12" lg="6" md="6" sm="12">
        <v-text-field
          v-model="search"
          outlined
          label="recherche"
        ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
        <v-select
        no-data-text="Pas de formation trouver"
          :items="formations"
          v-loading="loading"
          item-text="formation.name"
          item-value="formation.id"
          v-model="FormationChosed"
          @change="getStudentsForm"
          label="Selectionner Formation"
          outlined
        ></v-select>
        </v-col>
        </v-row>
      <v-toolbar
        flat
      >
      
        <v-toolbar-title>{{ FormationNamed }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="70%"
          
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#006772"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              {{formTitle}} Stagiaire
            </v-btn>
          </template>
          <v-card v-loading="loading">
            <v-card-title>
              <span class="text-h5">{{ formTitle }} Stagiaire</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <v-text-field
                    outlined
                      v-model="editedItem.firstname"
                      label="Nom"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <v-text-field
                    outlined
                      v-model="editedItem.lastname"
                      label="Prenom"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <v-text-field
                    outlined
                      v-model="editedItem.phone"
                      label="Telephone"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <v-text-field
                    outlined
                      v-model="editedItem.email"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <v-select
                    outlined
                      v-model="editedItem.class_id"
                      :items="formations"
                      item-text="formation.name"
                      item-value='formation.id'
                      label="Formation"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                {{ formTitle }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card v-loading="loading">
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Fermer</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <span>Pas de data trouver</span>
    </template>
  </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {

        data: () => ({

          loading: false,
        FormationChosed : '',
        FormationNamed : '',
        search: '',
        dialog: false,
        dialogDelete: false,
        headers: [
        {
          text: 'Nom',
          align: 'start',
          sortable: false,
          value: 'firstname',
        },
        { text: 'Prenom', value: 'lastname' },
        { text: 'Telephone', value: 'phone' },
        { text: 'E-mail', value: 'email' },
        { text: 'Actions', value: 'actions', sortable: false },
        ],
        students: [],
        formations: [],
        editedIndex: -1,
        editedItem: {
          firstname: '',
          lastname: '',
          phone: '',
          email: '',
          class_id: '',
          },
        defaultItem: {
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
            class_id: '',
          }
        }),

    mounted ()
    {
      this.loading = true
      this.$axios.get('formations')
      .then((response) => {
          this.formations = response.data;
          this.loading = false;
      })

      if (this.FormationChosed)
        this.FormationNamed = this.formations.find(x => x.formation.id === FormationChosed).formation.name
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Ajouter' : 'Modifier'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.students = []
      },

      editItem (item) {
        this.editedIndex = this.students.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.students.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.loading = true
        this.$axios.delete('student/'+this.students[this.editedIndex].id)
        .then((response) => {
          if (response.data.status){
          this.students.splice(this.editedIndex, 1)
          this.success(response.data.message)
          }
          else
            this.error(response.data.message)
        this.loading = false
        })
        .catch((err) => {
          console.log(err)
            this.error(response.data.message)
        })
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      success(message) {
        this.$notify({
          title: 'Success',
          message: message,
          type: 'success',
        });
      },
      error(message) {
        this.$notify({
          title: 'Error',
          message: message,
          type: 'error'
        });
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        this.loading = true
        if (this.editedIndex > -1) {
          this.$axios.put('student/'+this.students[this.editedIndex].id , this.editedItem)
          .then((response) => {
            console.log(response.data)
            if (this.FormationChosed == this.editedItem.class_id || !this.editedItem.class_id)
              Object.assign(this.students[this.editedIndex], this.editedItem)
            else
              this.students.splice(this.editedIndex, 1)
            if (response.data.status == 1)
              this.success(response.data.message)
            else
              this.error(response.data.message)
            this.close()
          }).catch((error) => {
            console.log(error)
            this.close()
          })
        } else {
          this.$axios.post('students' , this.editedItem)
          .then((response) => {
            if (this.FormationChosed == this.editedItem.class_id)
                if (response.data.status == 1)
                  this.students.push(response.data.student)
            this.close()
            if (response.data.status == 1)
              this.success(response.data.message)
            else
              this.error(response.data.message)
          }).catch((error) => {
            console.log(error)
              this.error(response.data.message)
            this.close()
          })
        }
        this.loading = false
      },
      getStudentsForm()
      {
        this.loading = true
        if (this.FormationChosed)
          this.FormationNamed = this.formations.find(x => x.formation.id === this.FormationChosed).formation.name
        this.$axios.get('students/'+this.FormationChosed)
          .then(({ data }) => {
            this.students = data.students;
            this.editItem.formation = this.FormationChosed;
        this.loading = false
          }).catch((e) => {
          console.log(e)
          // return e.response.data;
        });

      },
    },

}
</script>