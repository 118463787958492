<template>
        <v-col cols="12" lg="6" md="6" sm="6">
    <v-card
    class="mx-auto rounded-lg"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4 grey--text">
          {{title}}
        </div>
        <v-list-item-title :style="color" style="filter: brightness(0.7);" class="text-h4 mb-1">
        {{Data}}
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"
        class="rounded-lg elevation-4"
        :color="AvatarColor"
      >
      <v-icon :color="IconColor"  large>{{Icon}}</v-icon>
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
        </v-col>
</template>

<script>
export default {
    name: 'DashboardCard',
    props: ['AvatarColor' , 'Icon' , 'title' , 'Data' , 'IconColor'],
    computed: {
      color () {
        return 'color: ' + this.AvatarColor; + ";filter: brightness(1.85)";
      }
    },
    data: () => ({
})
}
</script>