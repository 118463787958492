import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Students from '../views/Students.vue'
import Classe from '../views/Classe.vue'
import Prof from '../views/Prof.vue'
import Payment from '../views/Payment.vue'
import Caisse from '../views/Caisse.vue'
import Users from '../views/Users.vue'
import Resume from '../views/Resume.vue'
import Profile from '../views/Profile.vue'
import Logout from '../views/Logout.vue'




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/students',
    name: 'Students',
    component: Students
  },
  {
    path: '/classes',
    name: 'Classe',
    component: Classe
  },
  {
    path: '/prof',
    name: 'Prof',
    component: Prof
  },
  {
    path: '/pay',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/caisse',
    name: 'Caisse',
    component: Caisse
  },
  {
    path: '/resume',
    name: 'Resume',
    component: Resume
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const isAuthenticated = localStorage.getItem('isAuthenticated');
  if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
  // if the user is not authenticated, `next` is called twice
  {next()}

  if (to.name === 'Login' && localStorage.getItem("token")) {
    router.push({
      name: 'Home'
    })
  }
})

export default router
