<template>
<v-container fluid>
    <v-row class="pa-5">
        <DashboardCard AvatarColor="#ff4d4f"  IconColor="white" Icon="mdi-file-certificate-outline" title="Formations" :Data="dataCard.formationsCount" />
        <DashboardCard AvatarColor="#52c41a" IconColor="white"  Icon="mdi-clipboard-account-outline" title="Stagiaire formation" :Data="dataCard.formationUsers" />
    </v-row>
    <v-row>
        <v-col cols="12" lg="8" md="8" sm="12">
            <v-card v-if="showData" class="pa-4 pb-8" v-loading="loading">
                <v-card-title>
                    <v-col cols="12" lg="8" md="12" sm="12">
                    <v-select
                    outlined
                    class="mx-2"
                    label="Année sélectionnée"
                    :items='years'
                    v-model="year"
                    @change="yearChanged"
                    >
                    </v-select>

                    <p class="text-center">
                    Les données de {{year}}
                    </p>
                    </v-col>
                    </v-card-title>
                    
            <line-chart  :library="{backgroundColor: '#7ca0d6'}" :stacked="true" prefix=" DH " thousands="," :round="1" :zeros="true" :data="dataChart"></line-chart>
            </v-card>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="12">
            <v-card  class=" pa-2">
                <v-card-title class="black--text">Dernières opérations</v-card-title>
            <v-simple-table height="532px" fixed-header class="rounded-lg">
    <template v-slot:default>
      <thead class="rounded-lg">
        <tr>
          <th class="text-left">
            Montant
          </th>
          <th class="text-left">
            Mois paye
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in latest"
          :key="item.id"
        >
          <td>{{ item.montant }} DH</td>
          <td>{{ item.month }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import DashboardCard from '../components/DashboardCard.vue'

export default {
    components: {
        DashboardCard
    },
    data: () => ({
desserts: [],
years: ['2019' , '2020' ,'2021' , '2022' , '2023'],
year: '2021',
loading: false,
dataChart: [],
latest: [],
dataCard: [],
showData: false,
    }),

    mounted() {

      if (localStorage.getItem('role') == 'admin')
        this.showData = true;
    else
        this.showData = false;

        this.loading = true
        this.$axios.get('/stats/cards').
        then(({ data}) => {
        this.loading = false
            this.dataCard = data
        }).catch((e) => {
            console.log(e)
        })

        this.$axios.get('/stats/chart')
        .then(({ data }) => {
            this.dataChart = data.year
            this.loading = false
        }).catch((e) => {
            console.log(e)
        })

        this.$axios.get('/stats/latest')
        .then(({ data }) => {
            this.latest = data
            this.loading = false
            console.log(data)
        }).catch((e) => {
            console.log(e)
        })
    },

    methods: {
        yearChanged(){
        this.loading = true
            this.$axios.get('/stats/chart?year='+this.year)
        .then(({ data }) => {
        this.loading =false
            this.dataChart = data.year
            console.log(data)
        }).catch((e) => {
            console.log(e)
        })
        },
        typeChanged(){
        this.loading = true
            this.$axios.get('/stats/chart?year='+this.year)
        .then(({ data }) => {
        this.loading =false
            this.dataChart = data.year
            console.log(data)
        }).catch((e) => {
            console.log(e)
        })
        }
    }
}
</script>