<template>
<v-container fluid>

    <v-row>
        <v-col>
  <v-data-table
    :headers="headers"
    :items="formations"
    sort-by="calories"
    class="elevation-1 rounded-lg"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Les Formations</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
        v-loading="loading"
          v-model="dialog"
          max-width="700px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Ajouter Formation

              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }} Formation</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="10"
                    md="6"
                  >
                    <v-text-field
                    required
                    outlined
                    v-model="editedItem.name"
                    :error-messages="nameErrors"
                    @input="$v.editedItem.name.$touch()"
                    @blur="$v.editedItem.name.$touch()"
                    label="Nom de la formation"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="6"
                  >
                    <v-text-field
                    required
                    outlined
                    v-model="editedItem.niv"
                    :error-messages="nivErrors"
                    @input="$v.editedItem.niv.$touch()"
                    @blur="$v.editedItem.niv.$touch()"
                    label="Niveau de la formation"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="6"
                  >

                <v-select
          :items="formateurs"
          v-model="editedItem.prof"
          :error-messages="profErrors"
          @input="$v.editedItem.prof.$touch()"
          @blur="$v.editedItem.prof.$touch()"
          item-text="name"
          item-value="id"
          label="Nom du Formateur"
          outlined
        ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="6"
                  >

                  <v-combobox
                  outlined
                  label="Les jours du formation"
                :items="days"
                :error-messages="jourErrors"
                    @input="$v.editedItem.jours.$touch()"
                    @blur="$v.editedItem.jours.$touch()"
                v-model="editedItem.jours"
                multiple
                ></v-combobox>
                    
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                    md="6"
                  >
                  <v-label>L'horaire de la formation</v-label>
                  <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="editedItem.debut"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
          outlined
          clearable
            v-model="editedItem.debut"
            :error-messages="debutErrors"
                    @input="$v.editedItem.debut.$touch()"
                    @blur="$v.editedItem.debut.$touch()"
            label="Heure de début"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu1"
          v-model="editedItem.debut"
          full-width
          format="24hr"
          @click:minute="$refs.menu1.save(editedItem.debut); menu1 = false"
        ></v-time-picker>
      </v-menu>

      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="editedItem.finir"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
          outlined
          clearable
            v-model="editedItem.finir"
            :error-messages="finirErrors"
                    @input="$v.editedItem.finir.$touch()"
                    @blur="$v.editedItem.finir.$touch()"
            label="Heure de Finir"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="editedItem.finir"
          full-width
          format="24hr"
          @click:minute="$refs.menu2.save(editedItem.finir); menu2 = false"
        ></v-time-picker>
      </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                {{ formTitle }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-loading="loading" v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Êtes-vous sûr de vouloir supprimer cette formation?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Non</v-btn>
              <v-btn color="red darken-1" text @click="deleteItemConfirm">Oui</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.horaire.start_hour="{ item }">
      <v-icon
      x-small
        dark
        class="black--text"
        color="green"
      >
      mdi-clock
      </v-icon>
        {{ item.horaire.start_hour }}
    </template>

     <template v-slot:item.horaire.days="{ item }">
      <v-icon
      small
        dark
        color="blue"
        class="black--text"
      >
      mdi-calendar-month
      </v-icon>
        {{ item.horaire.days }}
    </template>

    <template v-slot:item.horaire.end_hour="{ item }">
      <v-icon
      x-small
        dark
        color="red"
        class="black--text"
      >
      mdi-clock
      </v-icon>
        {{ item.horaire.end_hour }}
    </template>

    <template v-slot:item.formation.niv="{ item }">
      <v-chip
        color="blue-grey lighten-3"
        dark
        class="black--text"
      >
        {{ item.formation.niv }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
    <el-tooltip class="item" effect="light" content="Modifier ce classe" placement="top-start">

      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        color="teal darken-4"
      >
        mdi-pencil
      </v-icon>
    </el-tooltip>
    <el-tooltip class="item" effect="light" content="Suprimer ce classe" placement="top-start">
      <v-icon
        small
        @click="deleteItem(item)"
        color="red accent-4"
      >
        mdi-delete
      </v-icon>
    </el-tooltip>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
      >
        Rafraîchir
      </v-btn>
    </template>
  </v-data-table>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'

export default ({

  mixins: [validationMixin],

    validations: {
      editedItem: {
        name: { required },
        niv: { required },
        prof: { required },
        jours: { required },
        debut: { required },
        finir: { required },
      },
    },

    data: () => ({

        dialog: false,
        loading: false,
        menu2: false,
        menu1: false,
        formateurs: [],
      dialogDelete: false,
      days: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'],
      headers: [
        {
          text: 'Nom de la formation',
          align: 'start',
          sortable: false,
          value: 'formation.name',
        },
        { text: 'Niveau',
          align: 'center',
        value: 'formation.niv' },
        { text: 'Formateur', value: 'prof.name' },
        { text: 'Les jours', value: 'horaire.days' },
        { text: 'Heure de Début', value: 'horaire.start_hour'},
        { text: 'Heure de Finir', value: 'horaire.end_hour'},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      formations: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        niv: '',
        prof: '',
        jours: [],
        debut: '',
        finir: '',
      },
      defaultItem: {
        name: '',
        prof: '',
        jours: [],
        niv: '',
        debut: '',
        finir: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Ajouter' : 'Modifier'
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
      
        !this.$v.editedItem.name.required && errors.push('Le nom est requis.')
        return errors
      },
      profErrors () {
        const errors = []
        if (!this.$v.editedItem.prof.$dirty) return errors
      
        !this.$v.editedItem.prof.required && errors.push('Le formateur est requis.')
        return errors
      },
      jourErrors () {
        const errors = []
        if (!this.$v.editedItem.jours.$dirty) return errors
        
        !this.$v.editedItem.jours.required && errors.push('Les jours sont obligatoires.')
        return errors
      },
      nivErrors () {
        const errors = []
        if (!this.$v.editedItem.niv.$dirty) return errors
    
        !this.$v.editedItem.niv.required && errors.push('Le niveau est requis.')
        return errors
      },
      debutErrors () {
        const errors = []
        if (!this.$v.editedItem.debut.$dirty) return errors
        
        !this.$v.editedItem.debut.required && errors.push("L'heure de début est requise.")
        return errors
      },
      finirErrors () {
        const errors = []
        if (!this.$v.editedItem.finir.$dirty) return errors
        
        !this.$v.editedItem.finir.required && errors.push("L'heure de finir est requise.")
        return errors
      },
    },
    mounted() {

        // Formations Get
        this.$axios.get('formations')
          .then(({ data }) => {
            console.log(data)
              this.formations = data
          }).catch((e) => {
          console.log(e)
          // return e.response.data;
        });

        // Formateurs GET
        this.$axios.get('formateurs' )
        .then(({ data }) =>{
            this.formateurs = data
        }).catch((e) => {
            console.log(e)
        })
    },
    watch: {
      dialog (val) {
        this.$v.$reset()
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    methods: {
      editItem (item) {
        this.editedIndex = this.formations.indexOf(item)
        const days = Object.assign({}, item).horaire.days.split('-');
        this.editedItem.name = Object.assign({}, item).formation.name
        this.editedItem.niv = Object.assign({}, item).formation.niv
        this.editedItem.jours = days
        this.editedItem.prof = Object.assign({}, item).prof.id
        this.editedItem.debut = Object.assign({}, item).horaire.start_hour
        this.editedItem.finir = Object.assign({}, item).horaire.end_hour


        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.formations.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
          this.loading = true
          this.$axios.delete('formationdel/'+this.formations[this.editedIndex].formation.id).
            then(({ data }) => {
                this.formations.splice(this.editedIndex, 1)
                this.loading = false
                this.success(data.message)
            }).catch((e) => {
                this.error(e.message)
            })
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

        success(message) {
        this.$notify({
          title: 'Success',
          message: message,
          type: 'success',
        });
      },

      error(message) {
        this.$notify({
          title: 'Error',
          message: message,
          type: 'error'
        });
      },

      save () {
          this.$v.$touch()
        console.log(this.editedItem)
          if (!this.$v.$error){
          this.loading = true
              if (this.editedIndex > -1) {
                  this.$axios.put('formationup/'+this.formations[this.editedIndex].formation.id,
                  this.editedItem).
                  then(({data})=> {
                        
                        Object.assign(this.formations[this.editedIndex], data)
                        this.loading = false
                        console.log(data.message)
                            this.success(data.message)
                        this.close()
                  }).catch((e) => {
                      
                    this.loading = false
                    this.error(e.message)
                    console.log('error: ', e)
                  })
              }
              else {
          this.$axios.post('formations' , this.editedItem)
          .then(({ data }) =>{
              console.log(data)
                this.formations.push(data)
              
          this.loading = false
          this.success(data.message)
              this.close()
          }).catch((e) => {
          this.loading = false
          this.success(e.message)
              console.log('error: ', e)
          })
        }
          }
      },
    },
})
</script>
