<template>
  <v-container fluid>
    <v-row>
      <v-overlay :value="loadInfo"></v-overlay>
      <v-dialog v-model="loadInfo" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Extraction des données en cours...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-col>
        <v-card>
          <v-toolbar :color="colorTab" dark flat>
            <v-toolbar-title>Resume pour Les formations</v-toolbar-title>

            <template v-slot:extension>
              <v-tabs v-model="tab">
                <v-tabs-slider color="#bae7ff"></v-tabs-slider>

                <v-tab
                  v-for="(item, index) in items"
                  :key="item"
                  @change="tabChanged(index)"
                >
                  {{ item }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in items" :key="item">
              <!-- Formation -->
              <v-data-table
                locale="fr"
                :search="search"
                v-if="table == 1"
                :headers="headersForm"
                :items="Formation"
                sort-by="calories"
                class="elevation-1 mt-4"
              >
                <template v-slot:top>
                  <v-row class="mx-2">
                    <v-col cols="12" lg="6" md="6" sm="12">
                      <v-text-field
                        v-model="selectedMonth1"
                        :error-messages="month1Errors"
                        @input="$v.selectedMonth1.$touch()"
                        @blur="$v.selectedMonth1.$touch()"
                        @click="menu3 = !menu3"
                        label="Choisir le mois"
                        readonly
                        outlined
                      ></v-text-field>
                      <v-dialog
                        ref="dialog"
                        v-model="menu3"
                        :return-value.sync="month1"
                        width="60%"
                        persistent
                      >
                        <v-card>
                          <v-card-title>Le mois</v-card-title>
                          <v-card-text>
                            <v-date-picker
                              v-model="month1"
                              landscape
                              :error-messages="month1Errors"
                              @input="$v.selectedMonth1.$touch()"
                              @blur="$v.selectedMonth1.$touch()"
                              type="month"
                              :color="colorTab"
                              year-icon="mdi-calendar-blank"
                              prev-icon="mdi-skip-previous"
                              next-icon="mdi-skip-next"
                              class="my-2"
                            >
                            </v-date-picker>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              :color="colorTab"
                              class="white--text"
                              @click="menu3 = !menu3"
                              >OK</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="12">
                      <v-select
                        no-data-text="Pas de formation trouver"
                        :items="FormationOptions"
                        :error-messages="FormationChosedErrors"
                        @input="$v.FormationChosed.$touch()"
                        @blur="$v.FormationChosed.$touch()"
                        item-text="name"
                        item-value="id"
                        v-model="FormationChosed"
                        label="Selectionner Formation"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-toolbar flat>
                    <v-toolbar-title>Formation</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn
                      :disabled="!showBtn"
                      class="primary"
                      @click="generatePDF('formation')"
                      >Imprimer</v-btn
                    >

                    <v-btn
                      class="mx-2 white--text"
                      :color="colorTab"
                      @click="getTheListFormation()"
                      >Chercher</v-btn
                    >
                  </v-toolbar>
                </template>

                <template v-slot:item.created_at="{ item }">
                  <div>
                    {{ item.created_at.split(" ")[0] }}
                  </div>
                </template>
              </v-data-table>

              <!-- Diplome -->

              <v-data-table
                locale="fr-FR"
                :search="search"
                v-if="table == 0"
                :headers="headersDip"
                :items="Diplome"
                sort-by="calories"
                class="elevation-1 mt-4"
              >
                <template v-slot:top>
                  <v-row class="mx-2">
                    <v-col cols="12" lg="6" md="6" sm="12">
                      <v-text-field
                        v-model="selectedMonth2"
                        :error-messages="month2Errors"
                        @input="$v.selectedMonth2.$touch()"
                        @blur="$v.selectedMonth2.$touch()"
                        @click="menu4 = !menu4"
                        label="Choisir le mois"
                        readonly
                        outlined
                      ></v-text-field>
                      <v-dialog
                        ref="dialog"
                        v-model="menu4"
                        :return-value.sync="month2"
                        width="60%"
                        persistent
                      >
                        <v-card>
                          <v-card-title>Le mois</v-card-title>
                          <v-card-text>
                            <v-date-picker
                              v-model="month2"
                              landscape
                              :color="colorTab"
                              :error-messages="month2Errors"
                              @input="$v.selectedMonth2.$touch()"
                              @blur="$v.selectedMonth2.$touch()"
                              type="month"
                              year-icon="mdi-calendar-blank"
                              prev-icon="mdi-skip-previous"
                              next-icon="mdi-skip-next"
                              class="my-2"
                            >
                            </v-date-picker>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              :color="colorTab"
                              class="white--text"
                              @click="menu4 = !menu4"
                              >OK</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="12">
                      <v-select
                        no-data-text="Pas de Filliere trouver"
                        :items="DiplomeOptions"
                        :error-messages="FilliereChosedErrors"
                        @input="$v.FilliereChosed.$touch()"
                        @blur="$v.FilliereChosed.$touch()"
                        item-text="name"
                        item-value="id"
                        v-model="FilliereChosed"
                        label="Selectionner Filliere"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-toolbar flat>
                    <v-toolbar-title>Diplome</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn
                      :disabled="!showBtn"
                      class="primary"
                      @click="generatePDF('diplome')"
                      >imprimer</v-btn
                    >

                    <v-btn
                      class="mx-2 white--text"
                      :color="colorTab"
                      @click="getTheListFilliere()"
                      >Chercher</v-btn
                    >
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                  <v-btn :color="colorTab" disabled class="white--text">
                    Pas d'informations pour le moment
                  </v-btn>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <img
      width="100"
      height="100"
      id="imageid"
      src="https://i.ibb.co/9N4M5fR/isigg.png"
      v-if="0"
    />
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import jsPDF from "jspdf";
import "jspdf-autotable";
const auth = {
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
};
export default {
  mixins: [validationMixin],
  validations: {
    selectedMonth2: { required },
    selectedMonth1: { required },
    FilliereChosed: { required },
    FormationChosed: { required },
  },
  data: () => ({
    loadInfo: false,
    tableTitle: "Diplome",
    loading: false,
    menu3: false,
    menu4: false,
    ImageData: "",
    tab: 0,
    showBtn: false,
    table: 1,
    FilliereChosed: null,
    FormationsTab: [],
    FillieresTab: [],
    totalDiplome: 0,
    totalFormation: 0,
    classInfo: [],
    Filliere: null,
    selectedMonth2: new Date().toISOString().substr(0, 7),
    selectedMonth1: new Date().toISOString().substr(0, 7),
    nameFilliere: "",
    nameFormation: "",
    FormationChosed: null,
    dipStudents: [],
    colorTab: "#722ed1",
    formStudents: [],
    items: ["Formation"],
    headersForm: [
      {
        text: "Nom",
        value: "firstname",
      },
      { text: "Prenom", value: "lastname" },
      { text: "Montant", value: "montant" },
      { text: "Date d'inscription", value: "created_at" },
      { text: "Mois", value: "month" },
      { text: "Verfication", value: "verif" },
    ],
    headersDip: [
      {
        text: "Nom",
        value: "firstname",
      },
      { text: "Prenom", value: "lastname" },
      { text: "Montant", value: "montant" },
      { text: "Mois", value: "month" },
      { text: "Date", value: "date" },
      { text: "Verfication", value: "verif" },
    ],
    Formation: [],
    search: "",
    Diplome: [],
    FormationOptions: [],
    DiplomeOptions: [],
    month1: new Date().toISOString().substr(0, 7),
    month2: new Date().toISOString().substr(0, 7),
  }),
  mounted() {
    function toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    }
    this.ImageData = toDataURL("https://i.ibb.co/9N4M5fR/isigg.png", function(
      dataUrl
    ) {
      return dataUrl;
    });
    // Get all Formations
    this.$axios
      .get("formationselect")
      .then(({ data }) => {
        this.FormationOptions = data;
        this.FormationsTab = data;
      })
      .catch((e) => {
        console.log(e);
        // return e.response.data;
      });
    // Get all filliere
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Ajouter" : "Modifier";
    },
    month1Errors() {
      const errors = [];
      if (!this.$v.selectedMonth1.$dirty) return errors;
      this.tab == 1 &&
        !this.$v.selectedMonth1.required &&
        errors.push("Le mois est requis.");
      return errors;
    },
    month2Errors() {
      const errors = [];
      if (!this.$v.selectedMonth2.$dirty) return errors;
      this.tab == 0 &&
        !this.$v.selectedMonth2.required &&
        errors.push("Le mois requis.");
      return errors;
    },
    FilliereChosedErrors() {
      const errors = [];
      if (!this.$v.FilliereChosed.$dirty) return errors;
      this.tab == 0 &&
        !this.$v.FilliereChosed.required &&
        errors.push("Le filliere requis.");
      return errors;
    },
    FormationChosedErrors() {
      const errors = [];
      if (!this.$v.FormationChosed.$dirty) return errors;
      this.tab == 1 &&
        !this.$v.FormationChosed.required &&
        errors.push("La formation requis.");
      return errors;
    },
  },
  watch: {
    menu4(val) {
      this.selectedMonth2 = this.month2;
    },
    menu3(val) {
      this.selectedMonth1 = this.month1;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      (this.Formation = []), (this.Diplome = []);
    },
    tabChanged(data) {
      this.$v.$reset();
      if (data == 1) {
        this.colorTab = "#096dd9";
        this.tableTitle = "Formations";
        if (this.Formation.length > 0) this.showBtn = true;
        else this.showBtn = false;
      } else {
        if (this.Diplome.length > 0) this.showBtn = true;
        else this.showBtn = false;
        this.colorTab = "#722ed1";
        this.tableTitle = "Diplomes";
      }
      this.table = data;
    },
    success(message) {
      this.$notify({
        title: "Success",
        message: message,
        type: "success",
      });
    },
    error(message) {
      this.$notify({
        title: "Error",
        message: message,
        type: "error",
      });
    },
    generatePDF(type) {
      this.loadInfo = true;
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      if (type == "formation") {
        const columns = [
          { title: "Nom", dataKey: "firstname" },
          { title: "Prenom", dataKey: "lastname" },
          { title: "Montant", dataKey: "montant" },
          { title: "Date d'inscription", dataKey: "created_at" },
          { title: "Mois", dataKey: "month" },
          { title: "Verification Code", dataKey: "verif" },
        ];
        const headers = [
          { title: "Nom", dataKey: "name" },
          { title: "Niveau", dataKey: "niv" },
          { title: "Les jours", dataKey: "days" },
          { title: "Formateur", dataKey: "profName" },
          { title: "Debut", dataKey: "start_hour" },
          { title: "Finir", dataKey: "end_hour" },
        ];
        // text is placed using x, y coordinates
        //   doc.setFontSize(16).text(headers, 0.5, 1.0);
        // create a line under heading
        doc.setLineWidth(0.01).line(0.5, 1.1, 8.0, 1.1);

        // Using autoTable plugin

        doc.autoTable({
          columns,
          columnStyles: {
            0: { cellWidth: 1, fontSize: 9 },
          },
          body: this.Formation,
          margin: { left: 0.5, top: 1.25 },
        });
        doc.autoTable({
          columnStyles: {
            0: { cellWidth: 2, fontSize: 12 },
          },
          head: [["Total"]],
          body: [[this.totalFormation + " DH"]],
          styles: { fillColor: "#000000" },
          startY: 9.8,
          margin: { left: 0.5, top: 1 },
        });
        var totalPages = doc.internal.getNumberOfPages();
        for (var i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
          // doc.setTextColor(150);
          doc.autoTable({
            columns: headers,
            body: this.classInfo,
            startY: 0.5,
            styles: { fillColor: "#001dff" },
            margin: { left: 0.5, top: 0 },
          });
          // doc.text(50, doc.internal.pageSize.height - 30, 'Watermark');
        }
      } else {
        const headers = [
          { title: "Nom", dataKey: "name" },
          { title: "Niveau", dataKey: "niv_form" },
        ];
        const columns = [
          { title: "Nom", dataKey: "firstname" },
          { title: "Prenom", dataKey: "lastname" },
          { title: "Montant", dataKey: "montant" },
          { title: "Mois", dataKey: "month" },
          { title: "Verification Code", dataKey: "verif" },
        ];
        doc.setLineWidth(0.01).line(0.5, 1.1, 8.0, 1.1);
        // Using autoTable plugin
        doc.text(7, 15, "Overflow 'ellipsize' (default)");
        doc.autoTable({
          columns,
          columnStyles: {
            text: { cellWidth: "auto" },
          },
          body: this.Diplome,
          margin: { left: 0.5, top: 1.25, bottom: 1.25 },
        });
        // doc.autoTable({
        //   columnStyles: {
        //       0: {cellWidth: 2 , fontSize: 20},
        //   },
        //   head: [['Total']],
        //   body: [[this.totalDiplome]],
        //   styles: { fillColor: "#000000" },
        //   // startY: 1,
        //   margin: { left: 0.5, top: 1 }
        // })
        var totalPages = doc.internal.getNumberOfPages();
        for (var i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
          // doc.setTextColor(150);
          doc.autoTable({
            columns: headers,
            body: this.classInfo,
            startY: 0.5,
            styles: { fillColor: "#001dff" },
            margin: { left: 0.5, top: 0 },
          });
          doc.autoTable({
            columnStyles: {
              0: { cellWidth: 2, fontSize: 12 },
            },
            head: [["Total"]],
            body: [[this.totalDiplome + " DH"]],
            styles: { fillColor: "#000000" },
            startY: 9.8,
            margin: { left: 0.5, top: 1 },
          });
          // doc.text(50, doc.internal.pageSize.height - 30, 'Watermark');
        }
      }
      // doc.addImage(this.ImageData, "PNG", 0, 10, 50, 50)
      doc.text(2, 10, "Groupe2is");
      this.loadInfo = false;
      window.open(doc.output("bloburl"));
    },
    getTheListFormation() {
      this.loadInfo = true;
      this.Formation = [];
      this.$axios
        .get(
          "paymentresum/" +
            this.FormationChosed +
            "/formation?date=" +
            this.selectedMonth1
        )
        .then(({ data }) => {
          this.loadInfo = false;
          console.log(data);
          if (data.status) {
            this.Formation = data.paymentInfo;
            this.classInfo = data.classInfo;
            this.showBtn = true;
            this.totalFormation = data.total;
          } else {
            this.error(data.message);
          }
        })
        .catch(({ error }) => {
          this.loadInfo = false;
          console.log(error);
        });
    },
    getTheListFilliere() {
      this.Diplome = [];
      this.$axios
        .get(
          "paymentresum/" +
            this.FilliereChosed +
            "/diplome?date=" +
            this.selectedMonth2
        )
        .then(({ data }) => {
          if (data.status) {
            this.Diplome = data.paymentInfo;
            this.classInfo = data.classInfo;
            this.showBtn = true;
            this.totalDiplome = data.total;
          } else {
            this.error(data.message);
          }
        })
        .catch(({ error }) => {
          console.log(error);
        });
    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    },
  },
};
</script>

<style scoped>
.notif {
  background-color: rgb(34, 155, 34);
}
</style>
