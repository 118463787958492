<template>
  <v-container fluid>

      <v-row justify="end" class="my-2 mx-2">
    <v-dialog
    v-loading="loading"
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          class="my-2"
          v-bind="attrs"
          v-on="on"
          v-if="roleBased"
        >
          Ajouter Staff
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
   
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }} Staff</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="6"
              >
                <v-text-field
                outlined
                  label="Nom et Prenom"
                  v-model="editedItem.name"
                  :error-messages="nameErrors"
                    @input="$v.editedItem.name.$touch()"
                    @blur="$v.editedItem.name.$touch()"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="8"
                md="6"
              >
                <v-text-field
                outlined
                  label="Email"
                  v-model="editedItem.email"
                  :error-messages="emailErrors"
                    @input="$v.editedItem.email.$touch()"
                    @blur="$v.editedItem.email.$touch()"
                  hint="Email du Staff"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="8">
                <v-text-field
                outlined
                  label="Mot de Passe"
                  v-model="editedItem.password"
                  :error-messages="passwordErrors"
                    @input="$v.editedItem.password.$touch()"
                    @blur="$v.editedItem.password.$touch()"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="8" md="6"
              >
                <v-select
                outlined
                  :items="['admin', 'staff']"
                  v-model="editedItem.role"
                  :error-messages="roleErrors"
                    @input="$v.editedItem.role.$touch()"
                    @blur="$v.editedItem.role.$touch()"
                  label="Role Du Staff"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Fermer
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            {{formTitle}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-loading="loading" v-model="dialogDelete" max-width="600px">
          <v-card class="text-center">
            <v-card-title class="text-h5">Voulez-vous vraiment supprimer ce Staff ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Non</v-btn>
              <v-btn color="red darken-1" text @click="deleteItemConfirm">Oui</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </v-row>
<v-divider></v-divider>
    <v-data-iterator
      :items="users"
      item-key="id"
      single-expand
      hide-default-footer
    >
      <template v-slot:default="{ items, isExpanded, expand }">
        <v-row 
        
        >
          <v-col
            v-for="item in items"
            :key="item.id"    
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
          <el-popover
    placement="top-end"
    trigger="hover"
    :content="item.role">
            <v-card slot="reference">
              <v-card-title style="cursor: s-resize" :class="roleColor(item)" @click="(v) => {expand(item, !isExpanded(item))}">
                <h4 class="white--text">{{ item.name }}</h4>
                <v-spacer></v-spacer>
                  <v-icon color="white" v-if="showBtn(item)" @click="editeUser(item)">
                      mdi-pencil
                  </v-icon>
                  <v-icon color="white" v-if="showBtn(item)" @click="deleteUser(item)">
                      mdi-delete
                  </v-icon>
              </v-card-title>
              
              <v-expand-transition>
              <v-list
                v-if="isExpanded(item)"
                dense
              >
                <v-list-item>
                  <v-icon small class="mx-2" >mdi-account-box-outline</v-icon>
                  <v-list-item-content class="">
                    {{ item.name }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-icon small class="mx-2" >mdi-at</v-icon>
                  <v-list-item-content class="">
                    {{ item.email }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-icon small class="mx-2" >mdi-account-lock-outline</v-icon>
                  <v-list-item-content class="">
                    {{ item.role }}
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="">
                   <v-icon @click="(v) => {expand(item, !isExpanded(item))}">
                       mdi-arrow-up-drop-circle-outline
                   </v-icon>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              </v-expand-transition>
            </v-card>
          </el-popover>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email , minLength } from 'vuelidate/lib/validators'

const auth = {
        headers: { Authorization:'Bearer ' + localStorage.getItem('token') }
    }
  export default {
     mixins: [validationMixin],

    validations: {
      editedItem: {
        name: { required },
        email: { required  , email },
        role: { required },
        password: { required , minLength: minLength(6) },
      },
    },
    data: () => ({
      singleExpand: false,
      users: [],
      show: false,
      visible: false,
      roleBased: false,
      loading: false,
      dialogDelete: false,
      userState: -1,
      role: ['staff' , 'Administrator'],

          dialog: false,
      editedItem: {
          name: '',
          email: '',
          role: '',
          password: '',
      },
      defaultItem: {
          name: '',
          email: '',
          role: '',
          password: '',
      }
    }),
    computed: {
      formTitle () {
        return this.userState === -1 ? 'Ajouter' : 'Modifier'
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
      
        !this.$v.editedItem.name.required && errors.push('Le nom est requis.')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.editedItem.email.$dirty) return errors
        !this.$v.editedItem.email.email && errors.push('Doit être une adresse e-mail valide')
        !this.$v.editedItem.email.required && errors.push("L'email est requis.")
        return errors
      },
      roleErrors () {
        const errors = []
        if (!this.$v.editedItem.role.$dirty) return errors
      
        !this.$v.editedItem.role.required && errors.push('Le nom est requis.')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.editedItem.password.$dirty) return errors
        !this.$v.editedItem.password.minLength && errors.push('Le mot de passe doit contenir au moins 6 caractères')
        !this.$v.editedItem.password.required && errors.push('Le mot de passe est requis.')
        return errors
      },
    },
    mounted() {
        this.$axios.get('user/all'  )
        .then(({data}) => {
            this.users = data.users;
        }).catch((e) => {
            console.log(e)
        })

        if (localStorage.getItem('role') != 'staff')
            this.roleBased = true
    },

    watch: {
      dialog (val) {
        this.$v.$reset()
        val || this.close()
      },
    },
    methods: {
        roleColor(item) {
            if (item.role == 'admin')
                return "red lighten-2";
            else
                return "blue lighten-1";
        },
        editeUser(item)
        {
            this.userState = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        },

        close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.userState = -1
        })
      },
      success(message) {
        this.$notify({
          title: 'Success',
          message: message,
          type: 'success',
        });
      },

      error(message) {
        this.$notify({
          title: 'Error',
          message: message,
          type: 'error'
        });
      },
      save() {
        this.$v.$touch()
        if (!this.$v.$error){
          this.loading = true
          if (this.userState == -1) {
          this.$axios.post('user/add' , this.editedItem  ).
          then(({ data}) => {
            this.loading = false
            if (data.status){
                this.users.push(data.user)
                this.success(data.message)
            } else
                this.error(data.message)            
          }).catch((e) => {
              this.error(e)
              console.error(e)
          })
          }
          else {
              const ind = this.userState
              this.$axios.put('user/otherupdate/'+this.users[this.userState].id,
                  this.editedItem  ).
                  then(({data})=> {
                      this.loading = false
                        if (data.status){
                            Object.assign(this.users[ind], data.user)
                            this.success(data.message)
                        }
                        else
                            this.error(data.message)
                        this.close()
                  }).catch((e) => {
                    this.loading = false
                    this.error(e.message)
                    console.log('error: ', e)
                  })
          }
        this.close()
        }
    },
    deleteUser (item) {
        this.userState = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
          this.loading = true
          const ind = this.userState
          this.$axios.delete('user/del/'+this.users[this.userState].id  ).
            then(({ data }) => {
                this.users.splice(ind, 1)
                this.loading = false
                this.success(data.message)
            }).catch((e) => {
                this.error(e.message)
            })
        this.closeDelete()
      },
    closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      showBtn(item) {
          
          if (item.email == localStorage.getItem('userEmail') || localStorage.getItem('role') == 'staff')
            return false;
        return true;

      },
  }
  }
</script>