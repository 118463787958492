import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from './plugins/customAxios.js';
import vuetify from './plugins/vuetify'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/fr'
import 'element-ui/lib/theme-chalk/reset.css'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import {Chart} from 'chart.js'
import Chartkick from 'vue-chartkick'


Vue.use(Chartkick.use(Chart));
Vue.use(Antd);

Vue.config.productionTip = false
// const axiosConfig = {
//   headers: { Authorization:'Bearer ' + localStorage.getItem('token') },
//   baseURL: 'http://demo.groupe2is.online/back/api/',
//   timeout: 30000,
// };
window.axios = require('axios')

Vue.use(ElementUI, { locale });
// Vue.prototype.$axios = axios.create(axiosConfig)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
