<template>
  <div class="pa-6">
    <v-expand-transition>
       <v-alert
       v-show="expand"
      dense
      type="error"
      style="text-center"
    >
      {{ alerttext }}
    </v-alert>
      </v-expand-transition>
      <el-button v-if="showBtn" type="primary" class='ma-2' @click="showHistory">Historique des operations</el-button>
      <el-button v-if="showBtn" type="danger" class='ma-2' @click="showDeletedPayments">Les Paiements Suprimer</el-button>

<el-dialog max-width="75%" title="Liste d'historique des operations sur caisse" class="rounded-lg" :visible.sync="dialogTableVisible">
  <el-table 
  :data="gridData"
   height="250"
   border
   >
    <el-table-column property="id" label="Id" width="50"></el-table-column>
    <el-table-column property="montant" label="Montant" width="200"></el-table-column>
    <el-table-column property="created_at" label="Date d'operation"></el-table-column>
  </el-table>
</el-dialog>
<el-dialog
   width="75%"
 title="Liste d'historique des operations sur caisse" class="rounded-lg" :visible.sync="dialogTableVisibleDelPay">
  <el-table 
  :data="gridDataDelPay"
   height="250"
   border
   >
    <el-table-column property="username" label="Staff" width="150"></el-table-column>
    <el-table-column property="student" label="L'eleve" width="200"></el-table-column>
    <el-table-column property="montant" label="Montant" ></el-table-column>
    <el-table-column property="date" label="Date d'operation" width="200"></el-table-column>

  </el-table>
</el-dialog>
          <DataCard class="rounded-lg mb-4" cardTitle="Total sur caisse" @vider-caisse="viderCaisse()" :showBtn="showBtn" :cardData="gains" cardColor="purple lighten-1" btncolor="purple lighten-5" ButtonIcon="mdi-delete-empty" @viderCaisse="viderCaisse" cardButton="Vider La caisse"/>
    <v-data-table
      v-if="!expand"
      :headers="headers"
      :items="desserts"
      item-key="id"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field
        outlined
        dense
          v-model="search"
          label="Recherche staff"
          class="mx-8"
        ></v-text-field>
      </template>
      <template v-slot:body.append>
        <tr>
          <td></td>
          <td colspan="4"></td>
        </tr>
      </template>
      <template v-slot:item.action="{ item }">
    <el-tooltip v-if="showBtn" class="item" effect="dark" content="Vider la caisse pour ce staff" placement="top-start">
    <v-btn v-if="showBtn" @click="logSelected(item)" color="primary" >
      <v-icon >mdi-delete-empty</v-icon>
    </v-btn>
    </el-tooltip>
  </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import DataCard from '../components/CaisseCard.vue'

  export default {

    data () {
      return {
        search: '',
        showBtn: false,
        dialogTableVisible: false,
        gridData: [],
        gridDataDelPay: [],
        dialogTableVisibleDelPay: false,
        dataTable: false,
        expand: false,
        multiLine: true,
        snackbar: false,
        text: ``,
        gains: '',
        alerttext: '',
        user: '',
        headers: [
          {
            text: 'Identifiant',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Nom du staff', value: 'staff_name' },
          { text: 'Montant sur Caisse', value: 'Total_caisse' },
          { text: "Action", value: "action" }

        ],
        desserts: [],
      }
    },
    components: {
      DataCard
    },
    mounted() {

      if (localStorage.getItem('role') == 'admin')
        this.showBtn = true
      else
        this.showBtn = false
this.$axios.get('caisse/total' )
.then(({ data }) => {

  this.gains = data.totalcaisse;
  
      }).catch( data => {

          if (data)
            console.log('You are not logged in');
            });


this.$axios.get('caisse/detail'  )
.then(({ data }) => {

  console.log(data)

  if (data.status == 0)
    {
      this.expand = true;
      this.alerttext = data.message;
    }
    else 
    {
      this.dataTable = true
      this.desserts = data;
    }
  
      }).catch( data => {

          if (data)
            console.log(data);
            });
    },
    methods: {

      viderCaisse: function()
      { 
          this.$axios.put('caisse/all')
                              .then(({ data }) => {

                            this.success(data.message)
                            this.$axios.get('caisse/total'  )
              .then(({ data }) => {

                this.gains = data.totalcaisse;
                
                    }).catch( data => {

                        if (data)
                          console.log('You are not logged in');
                          });
                

                this.$axios.get('caisse/detail'  )
              .then(({ data }) => {

                if (data.status == 0)
                  {
                    this.success(data.message)
                    this.alerttext = data.message;
                    this.desserts = []
                  }
                else
                  this.desserts = data;
                
                    }).catch( data => {

                        if (data)
                          console.log(data);
                          });
                                }).catch( data => {
                                  this.error("Vous n'êtes pas autorisé à faire cette action")
                                    console.log(data);
                                    });          
      },


      logSelected(item) {

        console.log(item.id);
        this.$axios.put('caisse/user/'+item.id)
                .then(({ data }) => {
                  this.success(data.status)

                  this.$axios.get('caisse/total'  )
.then(({ data }) => {
  this.gains = data.totalcaisse;
  
      }).catch( data => {

          if (data)
            console.log('You are not logged in');
            });
  

  this.$axios.get('caisse/detail'  )
.then(({ data }) => {
    console.log(data)
  if (data.status == 'emptyData')
    {
      this.expand = true;
      this.alerttext = data.message;
      this.desserts = []
    }
  else
    this.desserts = data;
  
      }).catch( data => {

          if (data)
            console.log(data);

            });

                   }).catch( data => {
                      console.log(data);
                      });          
      },

      showHistory()
      {
          this.$axios.get('/caisse/historique')
          .then(({ data }) => {
              this.dialogTableVisible = true
              this.gridData = data;
          }).catch((e) => {
              console.log(e)
          })
      },
      showDeletedPayments()
      {
          this.$axios.get('/deletedpayments')
          .then(({ data }) => {
              this.dialogTableVisibleDelPay = true
              this.gridDataDelPay = data;
          }).catch((e) => {
              console.log(e)
          })
      },
       success(message) {
        this.$notify({
          title: 'Success',
          message: message,
          type: 'success',
        });
      },

      error(message) {
        this.$notify({
          title: 'Error',
          message: message,
          type: 'error'
        });
      },
    },
  }
</script>