<template>
    
</template>

<script>
export default {

    mounted() {
    
this.$axios.post('user/logout', {})
.then(({ data }) => {

    this.$store.dispatch('logout')
    window.location.reload()
});
    },
}
</script>