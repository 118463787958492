<template>
<v-container fill-height class="">
<v-row no-gutters>
      <v-col
        md="6"
        offset-md="3"
      >
<v-card 
  class="pa-10 elevation-3 rounded-lg"
>
    <v-chip
    outlined
      class="ma-2 "
      color="primary"
    >
      {{ roleuser }}
    </v-chip>
  <form >
    <v-text-field
      v-model="name"
      :error-messages="nameErrors"
      :counter="30"
      label="Nom"
      required
      prepend-icon="mdi-account-circle"
      @input="$v.name.$touch()"
      @blur="$v.name.$touch()"
    ></v-text-field>
    <v-text-field
      v-model="email"
      :error-messages="emailErrors"
      label="E-mail"
      required
      prepend-icon="mdi-at"
      @input="$v.email.$touch()"
      @blur="$v.email.$touch()"
    ></v-text-field>
    <v-text-field
    v-model="password"
    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
    :error-messages="passwordErrors"
      required
      @input="$v.password.$touch()"
      @blur="$v.password.$touch()"
    :type="show1 ? 'text' : 'password'"
    name="input-10-1"
    label="Mot de passe"
    hint="At least 8 characters"
    prepend-icon="mdi-lock"
    counter
    @click:append="show1 = !show1"
    ></v-text-field>
    <v-btn
      class="mr-4 pa-4"
      color="primary"
      @click="submit"
      :disabled="!valid"
    >
      Mis a Jour
    </v-btn>
    <v-btn class="red--text" outlined @click="clear">
      Effacer
    </v-btn>
  </form>
</v-card>
<v-snackbar
      v-model="snackbar"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
   </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, minLength, email } from 'vuelidate/lib/validators'

  export default {
    mixins: [validationMixin],

    validations: {
      name: { required },
      email: { required, email },
      password: { minLength: minLength(6)}
    },

    data: () => ({
      valid: true,
      name: '',
      email: '',
      roleuser: '',
      role: false,
      snackbar: false,
      snackbarText: '',
      show1: false,
      password: '',
    }),

    mounted (){


    this.$axios.get('/user/info')
.then(({ data }) => {
  if (data.role == 'admin')
    this.role = true;
  else
    this.role = false;
    this.roleuser = data.role;
  this.userid = data.id;

  
      }).catch( data => {

          if (data)
            console.log('You are not logged in');
            });
    
this.$axios.get('/user/info')
.then(({ data }) => {

  this.name = data.name;
  this.email = data.email;
  this.select = data.role;
  
      }).catch( data => {

          if (data)
            console.log('You are not logged in');
            });
    },

    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.required && errors.push('Le nom est requis.')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Doit être une adresse e-mail valide')
        !this.$v.email.required && errors.push("L'e-mail est requis")
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.minLength && errors.push('Le mot de passe doit contenir au moins 6 caractères')
        return errors
      },
    },

    methods: {
     
      submit () {
        this.$v.$touch()
        if (!this.$v.$error){
        this.$axios.put('user/update', {
              name: this.name,
              email: this.email,
              password: this.password,
        }).then((data) =>{
        this.success('Profile modifier avec succes')
          this.$store.dispatch('updateUser' , data.data)
          this.snackbar = true
          this.snackbarText = 'Profile modifier avec succes'
                  })
        }
      },
      clear () {
        this.$v.$reset()
        this.name = ''
        this.email = ''
      },

         success(message) {
        this.$notify({
          title: 'Success',
          message: message,
          type: 'success',
        });
      },
      error(message) {
        this.$notify({
          title: 'Error',
          message: message,
          type: 'error'
        });
      },
    },
  }
</script>