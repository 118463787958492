<template>
 <v-card
    class="pa-2"
    outlined
      tile
        :color="cardColor"
        dark
        :class="classe"
        elevation="4"
      >
        <v-card-title class="text-lg-h5">
          {{ cardTitle }}
        </v-card-title>

        <v-card-text class="text-h4 font-weight-bold">
          {{ cardData }}
        </v-card-text>
        <v-card-actions v-if="showBtn">
        <v-spacer></v-spacer>

<el-tooltip class="item" effect="dark" content="Vider la caisse" placement="top-start">
        <v-btn :color="btncolor" @click="$emit('vider-caisse')" icon >
            <v-icon x-large> {{ ButtonIcon }} </v-icon>
            </v-btn>
    </el-tooltip>
        </v-card-actions>
    </v-card>
</template>

<script>
  export default {

    name: 'DataCard',
    props: ['cardTitle' , 'showBtn', 'cardData' , 'cardColor' , 'cardButton' , 'ButtonIcon' , "classe" , 'Members' , 'btncolor'],
    data: () => ({
    }),
  }
</script>

<style>
  /* .v-sheet--offset {
    top: -24px;
    position: relative;
  } */
</style>