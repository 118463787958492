<template>
  <v-app id="inspire">
    <v-navigation-drawer
    v-if="!!$store.state.isAuthenticated"
      v-model="drawer"
      app
      color="pink darken-3"
      expand-on-hover
      :mini-variant.sync="mini"
      permanent
    >
    <!-- <v-img src="https://i.ibb.co/NNkSRBp/wave-haikei.png"></v-img> -->
      <v-list-item class="px-2">
        <v-list-item-avatar color="white" size="40">
        <span class="blue--text text-h5">{{ user.initials }}</span>
        </v-list-item-avatar>

        <v-list-item-title class="white--text">{{ userName }}</v-list-item-title>

        
      </v-list-item>

      <v-divider></v-divider>

      <v-list 
      shaped>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.to"
          color="red"
        >
          <v-list-item-icon>
            <v-icon :color="item.color">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 white--text font-weight-bold">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
        
    <v-app-bar
    
     app
     hide-on-scroll
     color="#6A76AB"
     src="https://image.freepik.com/free-photo/blur-library-interior-background_74190-5467.jpg"
       class="white--black elevation-2"
    v-if="!!$store.state.isAuthenticated"
    >
      <v-toolbar-title >
            <v-img
              max-height="100"
              max-width="100"
             src="https://i.imgur.com/TxkVGSF.png"></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    
      <v-menu
        bottom
        min-width="200px"
        rounded
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            x-large
            v-on="on"
          >
            <v-avatar
              size="48"
            >
            <v-icon color="red">mdi-shield-account</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
              >
            <v-icon>mdi-shield-account</v-icon>
              </v-avatar>
              <h3>{{ user.fullName }}</h3>
              <p class="text-caption mt-1">
                {{ user.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                to="/profile"
              >
                Editer le profil
              </v-btn>
         
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                to="Logout"
              >
                Déconnecter
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    data: () => ({
      mini: true,
      userName: '',
      user: {
        initials: localStorage.getItem("userName").charAt(0)+localStorage.getItem("userName").charAt(1),
        src: 'https://cdn.vuetifyjs.com/images/john.jpg',
        fullName: localStorage.getItem("userName"),
        email: localStorage.getItem("userEmail"),
      },
      cards: ['Today', 'Yesterday'],
      drawer: null,
      items: [
],
    }),
    mounted() {
    this.userName = localStorage.getItem('userName')

      if (localStorage.getItem('role') == 'admin')
        this.items = [
          { title: 'Tableau de Bord', icon: 'mdi-view-dashboard', color: '#fff' , to: '/'},
          { title: 'Les Stagiaires', icon: 'mdi-account-group' , color: '#fff' , to: '/students'},
          { title: 'Les Classes', icon: 'mdi-file-certificate' , color: '#fff' , to: '/classes'},
          { title: 'Les Formateurs', icon: 'mdi-badge-account-alert' , color: '#fff' , to: '/prof'},
          { title: 'Les Paiments', icon: 'mdi-cash-multiple' , color: '#fff' , to:'/pay'},
          { title: 'La Caisse', icon: 'mdi-cash-register' , color: '#fff' , to:'/caisse'},
          { title: 'Resume', icon: 'mdi-clipboard-text-clock-outline' , color: '#fff' , to:'/resume'},
          { title: 'Les Staffs', icon: 'mdi-shield-account' , color: '#fff' , to: '/users'},
          { title: 'Profile', icon: 'mdi-account-circle' , color: '#fff' , to: '/profile'},
          { title: 'Se déconnecter', icon: 'mdi-logout' , color: 'black' , to: '/logout'},
        ]
        else
        {
          this.items = [
          { title: 'Tableau de Bord', icon: 'mdi-view-dashboard', color: '#b5f5ec' , to: '/'},
          { title: 'Les Stagiaires', icon: 'mdi-account-group' , color: '#b5f5ec' , to: '/students'},
          { title: 'Les Classes', icon: 'mdi-file-certificate' , color: '#b5f5ec' , to: '/classes'},
          { title: 'Les Formateurs', icon: 'mdi-badge-account-alert' , color: '#b5f5ec' , to: '/prof'},
          { title: 'Les Paiments', icon: 'mdi-cash-multiple' , color: '#b5f5ec' , to:'/pay'},
          { title: 'Resume', icon: 'mdi-clipboard-text-clock-outline' , color: '#fff' , to:'/resume'},
          { title: 'La Caisse', icon: 'mdi-cash-register' , color: '#fff' , to:'/caisse'},
          { title: 'Les Staffs', icon: 'mdi-shield-account' , color: '#b5f5ec' , to: '/users'},
          { title: 'Profile', icon: 'mdi-account-circle' , color: '#b5f5ec' , to: '/profile'},
          { title: 'Se déconnecter', icon: 'mdi-logout' , color: 'black' , to: '/logout'},
          ]
        }
  }
  }
</script>