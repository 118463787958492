<template >

<v-container fill-height fluid class="container-background" >
  
<div>

</div>

<v-card
 class=" mx-auto pa-4 rounded-lg"
  elevation="5"
  max-width="750"
  min-width="400"
>
<v-progress-linear
      :indeterminate="loading"
      :active="loading"
      color="cyan"
      top
      height="7"
    ></v-progress-linear>
<v-expand-transition>
       <v-alert
       v-show="expand"
      dense
      :type="typeAlert"
      style="text-center"
    >
      {{ alerttext }}
    </v-alert>
      </v-expand-transition>
<v-card-title class="justify-center" >
  <v-img
  max-height="100"
  max-width="100"
  src="https://i.imgur.com/TxkVGSF.png"></v-img>
    
   </v-card-title>
  <form ref="form" class="pa-5">
    <v-text-field
      v-model="email"
      :error-messages="emailErrors"
      label="E-mail"
      required
      outlined
      @input="$v.email.$touch()"
      @blur="$v.email.$touch()"
    ></v-text-field>
    <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            :error-messages="passwordErrors"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            outlined
            label="Mot de passe"
            hint="Au moins 6 caractères"
            @click:append="show1 = !show1"
          ></v-text-field>
  </form>
  <v-card-actions class="pa-5">
    <v-btn width="60%"
      class="mr-4 mt-4 mb-2"
      @click="submit"
      color="primary"
    >
      Connexion
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn width="30%" class="mt-4 mb-2" @click="clear" outlined color="red">
      Effacer
    </v-btn>
  </v-card-actions>
</v-card>

</v-container>
</template>


<script>
  import { validationMixin } from 'vuelidate'
  import { required, maxLength, email } from 'vuelidate/lib/validators'

  export default {
    mixins: [validationMixin],

    validations: {
      email: { required, email },
      password: { required},
    },

    data: () => ({
      email: '',
      loading: false,
      show1: false,
      alerttext: '',
      expand: false,
      typeAlert: 'info',
      password: '',
    }),

    computed: {
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Doit être une adresse e-mail valide')
        !this.$v.email.required && errors.push("L'e-mail est requis")
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.required && errors.push("Le mot de passe est requis")
        return errors
      },
    },

    methods: {

      submit () {

        this.$v.$touch()
        if (!this.$v.$error){
        this.loading = true;
        setTimeout(() => this.loading = false , 3000)
        
        this.$store.dispatch('login' , { 
            email: this.email,
            password: this.password
        }).then(( data ) => {

            console.log(data)
          if (data && data.status == 'error')
          {
            this.typeAlert = 'error'
            this.expand = true;
            this.alerttext = "les informations d'identification invalides"
            setTimeout(() => this.expand = false , 2000);
          }
          else
          {
            this.typeAlert = 'success'
            this.expand = true;
            this.alerttext = "connecté avec succès"
            setTimeout(() => this.expand = false , 2000);
            setTimeout(() => this.loading = false , this.$router.push('/') , window.location.reload()  , 5000)

          }
          
        })
        }
      },
      clear () {
        this.$v.$reset()
        this.password = ''
        this.email = ''
      },

      
    },
  }
</script>



<style lang="css" scoped>


  .container-background {
    background-image: url('https://i.pinimg.com/736x/6c/e8/68/6ce8684fa8e70d828bd5a5102124fb58--health-and-safety-new-trends.jpg');
    background-repeat: no-repeat;
  background-size: cover;
  }

</style>